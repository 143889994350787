import { bindable, customElement, inject, observable } from "aurelia-framework";
import Client from "../api/client";
import { FlashService } from "../flash/flash-service";
import { UserClient } from "../api/user-client";
import {ArtificialIntelligence} from "../artificial-intelligence/helper/artificial-intelligence";

@inject(Client, FlashService, UserClient, ArtificialIntelligence)
@customElement("sio-translatable")
export class Translatable {
    @bindable field = null;
    @bindable({ defaultBindingMode: 2 }) value = "";
    @bindable checkboxTranslation;
    @bindable currentValue;

    static locales;

    constructor(client, flash, userClient, artificialIntelligence) {
        this.client = client;
        this.flash = flash;
        this.userClient = userClient;
        this.artificialIntelligence = artificialIntelligence;

        this.isLoading = false;
        this.preventCurrentVauleRerender = true; // Not reload on first render

        this.isArtificialIntelligenceEnabled = this.userClient?.getUser()?.
            instance?.settings?.artificialIntelligenceInstanceSettings?.isActive;
    }

    async bind() {
        this.hasTranslation = this.userClient.getUser().instance?.settings?.translationInstanceSettings?.hasTranslation;
        this.checkboxTranslation = this.checkboxTranslation ?? !this.userClient.getUser().settings.userSettings.disableAutomaticTranslation;
        this.locales = this.getLocales();
        this.currentLocale = this.locales[0];
        this.locales?.map(locale=> {
            if(!this.value) {
                this.field.setValue({});
                // this.value = {};
            }
            if(locale in this.field.value) {
                return;
            }
            this.field.setValue({...this.field.value, [locale]: ""});
                // this.value[locale] = "";
        });
        this.currentValue = this.field.value?.[this.currentLocale] ?? "";
    }

    setLocale(locale) {
        this.currentLocale = locale;
        this.preventCurrentVauleRerender = true;
        this.currentValue = this.value[this.currentLocale] ?? "";
    }

    getLocales() {
        return Translatable.locales ?? this.userClient.getUser()?.instance?.settings?.instanceGeneral
            ?.contentLocales ?? ["de"];
    }

    switchCheckbox() {
        this.checkboxTranslation = !this.checkboxTranslation;
    }

    valueChanged() {
       this.currentValue = this.value[this.currentLocale]
    }

    currentValueChanged(newValue) {
        if (this.preventCurrentVauleRerender) {
            this.preventCurrentVauleRerender = false;
            return;
        }
        if (typeof newValue !== "string") {
            newValue = "";
        }

        //this need to be set for the char count
        this.field.setValue({...this.value, [this.currentLocale]: newValue});

        this.value = {...this.value, [this.currentLocale]: newValue}

        // If autotranslation is on
        if (this.checkboxTranslation && this.field.subType !== "htmlarea") {
            this.autoTranslation(newValue);
            return;
        }

    }

    autoTranslation(newValue) {

        this.value[this.currentLocale] = newValue ?? "";

        if (newValue == "" || newValue == null) {
            this.locales.forEach((locale) => {
                console.log("locale", locale);
                this.value[locale] = "";
            });
            this.isLoading = false;
            return;
        }

        if (!this.hasTranslation) {
            return;
        }

        this.isLoading = true;

        const text = this.value[this.currentLocale] ?? "";
        const remainingLanguages = this.locales.filter(
            (lang) => lang !== this.currentLocale
        );

        let requestsArray = [];
        remainingLanguages.forEach(async (lang) => {
            requestsArray.push(this.translate(this.currentLocale, lang, text));
        });

        Promise.all(requestsArray).then((values) => {
            console.log(values);
            remainingLanguages.forEach((lang, index) => {
                this.value[lang] = values[index];
            });
            this.isLoading = false;
            console.debug("value", this.value);
        });
    }

    async openArtificialIntelligenceDialog(context) {
        const {id, modelId} = context?.parent?.contextObjectRef ?? {};

        try {
            const res = await this.artificialIntelligence.generate({id: id, modelId: modelId});

            if (this.artificialIntelligence.validateResponseObj(res)) {
                return;
            }

            this.setCurrentFieldValue(res.response);
        } catch (error) {
            console.error(error);
            this.flash.error(error?.localizedMessage ?? error?.message ?? error ?? "Error");
        }
    }

    async translateFrom(localeFrom) {
        this.isLoading = true;
        const text = this.value[localeFrom] ?? "";
        const response = await this.translate(
            localeFrom,
            this.currentLocale,
            text
        );

        this.setCurrentFieldValue(response);
    }

    translate(localeFrom, localeTo, text) {
        return new Promise((resolve, reject) => {
            this.client
                .post("translate", {
                    localeFrom: localeFrom,
                    localeTo: localeTo,
                    text: text,
                })
                .then((response) => {
                    if (!response.data) {
                        return;
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error?.data?.message) {
                        this.flash.error(error.data.message);
                        reject(error?.data?.message);
                    }
                });
        });
    }

    setCurrentFieldValue(value) {
        this.value[this.currentLocale] = value;
        this.field.setValue({...this.value, [this.currentLocale]: value});

        if (this.currentValue !== this.value[this.currentLocale]) {
            this.preventCurrentVauleRerender = true;
        }

        this.currentValue = this.value[this.currentLocale];
        this.isLoading = false;
    }
}
